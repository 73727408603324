import isNil from 'lodash/isNil';
import Cluster from './cluster.svg';
import brandIcon from './brand.svg';
import brandHoverIcon from './brandHover.svg';
import userMarkerIcon from './userMarker.svg';
import parkingIcon from './parking.svg';
import cityMarkerIcon from './cityMarker.svg';
import dropOffIcon from './dropOff.svg';
import dropOffBusinessIcon from './dropOffBusiness.svg';
import geoLocationIconOn from './geolocation-on.svg';
import geoLocationIconOff from './geolocation-off.svg';
import heartFavouriteLocationIcon from './heartFavouriteLocation.svg';
import homeFavouriteLocationIcon from './homeFavouriteLocation.svg';
import workFavouriteLocationIcon from './workFavouriteLocation.svg';
import dropOffSelectedIcon from './dropOffSelected.svg';
import { importFont } from './importFont';

const circleSmallIcons = ({ vehicleCount, color = '#009900', opacity = 1 }) => (isNil(vehicleCount) ? '' : `
  <g transform="translate(32)">
        <circle opacity="${opacity}" cx="9" cy="10.5" r="8.5" fill="#FFF" stroke="${color}"/>
        <text opacity="${opacity}" fill="#333" style="font-family: 'NEXT Book';" font-size="12">
            <tspan x="${vehicleCount > 9 ? '3' : '5.5'}" y="14.5">${vehicleCount}</tspan>
        </text>
  </g>
`);

const circleBigIcons = ({ vehicleCount, color = '#009900', opacity = 1 }) => (isNil(vehicleCount) ? '' : `
  <g transform="translate(14)">
    <circle opacity="${opacity}" cx="39" cy="12" r="8.5" fill="#FFF" stroke="${color}"/>
    <text opacity="${opacity}" fill="#333" style="font-family: 'NEXT Book';" font-size="12">
      <tspan x="${vehicleCount > 9 ? '33' : '36'}" y="16">${vehicleCount}</tspan>
    </text>
  </g>
`);

const oneWayIconSvgContent = (color, vehicleCount) => `
<svg viewBox="0 0 56 61" width="56px" height="61px" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
  <defs>
    <filter id="filter0_d_8000_25766" x="1.96924" y="4.40002" width="48.96" height="55.6801" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="3"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8000_25766"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8000_25766" result="shape"/>
    </filter>
    ${importFont()}
  </defs>
  <g filter="url(#filter0_d_8000_25766)" transform="matrix(1, 0, 0, 1, -0.3667300343513489, 1.492671012878418)">
    <path d="M7.96924 27.2789C7.96924 16.8596 16.2355 8.40002 26.4492 8.40002C36.663 8.40002 44.9292 16.8596 44.9292 27.2789C44.9292 36.6781 38.1736 44.5725 29.2219 45.9467V49.325C29.2219 50.8465 27.9805 52.08 26.4492 52.08C24.918 52.08 23.6766 50.8465 23.6766 49.325V45.9467C14.7249 44.5725 7.96924 36.6781 7.96924 27.2789Z" fill="#009900"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 27.355 46.152 L 28.628 45.957 C 36.828 44.698 43.062 37.449 43.062 28.772 C 43.062 19.15 35.437 11.393 26.082 11.393 C 16.728 11.393 9.103 19.15 9.103 28.772 C 9.103 37.449 15.337 44.698 23.537 45.957 L 24.81 46.152 L 24.81 50.818 C 24.81 51.502 25.371 52.073 26.082 52.073 C 26.794 52.073 27.355 51.502 27.355 50.818 L 27.355 46.152 Z M 28.855 47.439 C 37.807 46.065 44.562 38.171 44.562 28.772 C 44.562 18.352 36.296 9.893 26.082 9.893 C 15.869 9.893 7.603 18.352 7.603 28.772 C 7.603 38.171 14.358 46.065 23.31 47.439 L 23.31 50.818 C 23.31 52.339 24.551 53.573 26.082 53.573 C 27.614 53.573 28.855 52.339 28.855 50.818 L 28.855 47.439 Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 24.874 35.521 C 24.062 35.521 23.405 36.179 23.405 36.991 C 23.405 37.802 24.062 38.46 24.874 38.46 C 25.685 38.46 26.343 37.802 26.343 36.991 C 26.343 36.179 25.685 35.521 24.874 35.521 Z M 22.335 36.991 C 22.335 35.588 23.472 34.452 24.874 34.452 C 26.276 34.452 27.413 35.588 27.413 36.991 C 27.413 38.393 26.276 39.529 24.874 39.529 C 23.472 39.529 22.335 38.393 22.335 36.991 Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 16.989 36.991 C 16.989 36.695 17.229 36.456 17.524 36.456 L 22.868 36.456 C 23.164 36.456 23.403 36.695 23.403 36.991 C 23.403 37.286 23.164 37.525 22.868 37.525 L 17.524 37.525 C 17.229 37.525 16.989 37.286 16.989 36.991 Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 16.989 25.634 C 16.989 25.339 17.229 25.1 17.524 25.1 L 20.549 25.1 C 21.763 25.1 22.874 25.786 23.417 26.873 C 23.417 26.873 23.417 26.873 23.417 26.873 L 25.203 30.444 L 27.544 30.444 C 29.315 30.444 30.751 31.879 30.751 33.651 L 30.751 35.655 C 30.751 36.688 29.913 37.525 28.88 37.525 L 26.876 37.525 C 26.581 37.525 26.341 37.286 26.341 36.991 C 26.341 36.695 26.581 36.456 26.876 36.456 L 28.88 36.456 C 29.323 36.456 29.681 36.097 29.681 35.655 L 29.681 33.651 C 29.681 32.47 28.725 31.513 27.544 31.513 L 24.872 31.513 C 24.67 31.513 24.484 31.399 24.394 31.218 L 22.46 27.351 C 22.098 26.627 21.358 26.169 20.549 26.169 C 20.549 26.169 20.549 26.169 20.549 26.169 L 17.524 26.169 C 17.229 26.169 16.989 25.93 16.989 25.634 Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 16.989 30.978 C 16.989 30.683 17.229 30.444 17.524 30.444 L 24.872 30.444 C 25.168 30.444 25.407 30.683 25.407 30.978 C 25.407 31.274 25.168 31.513 24.872 31.513 L 17.524 31.513 C 17.229 31.513 16.989 31.274 16.989 30.978 Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 36.278 23.813 C 36.278 24.108 36.039 24.347 35.743 24.347 L 27.849 24.347 C 27.553 24.347 27.314 24.108 27.314 23.813 C 27.314 23.517 27.553 23.278 27.849 23.278 L 35.743 23.278 C 36.039 23.278 36.278 23.517 36.278 23.813 Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 36.122 23.434 C 36.33 23.643 36.33 23.982 36.122 24.191 L 34.3 26.012 C 34.091 26.221 33.752 26.221 33.543 26.012 C 33.335 25.804 33.335 25.465 33.543 25.256 L 34.987 23.812 L 33.543 22.369 C 33.335 22.16 33.335 21.821 33.543 21.612 C 33.752 21.404 34.091 21.404 34.3 21.612 L 36.122 23.434 Z" fill="white"/>
    ${circleSmallIcons({ vehicleCount })}
</svg>
`;

export const oneWay = () => `
<svg viewBox="0 0 72 74" width="72px" height="74px" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
  <defs>
    <filter id="filter0_d_8000_25695" x="4" y="0" width="64" height="73.4546" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="3"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8000_25695"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8000_25695" result="shape"/>
    </filter>
    ${importFont()}
  </defs>
  <path opacity="0.2" d="M 35.156 69.716 C 40.796 69.716 45.368 68.192 45.368 66.312 C 45.368 64.432 40.796 62.908 35.156 62.908 C 29.516 62.908 24.943 64.432 24.943 66.312 C 24.943 68.192 29.516 69.716 35.156 69.716 Z" fill="#333333"/>
  <g filter="url(#filter0_d_8000_25695)" transform="matrix(1, 0, 0, 1, -0.8441980481147766, 1.7159079313278198)">
    <path d="M10 30.5612C10 15.902 21.63 4 36 4C50.37 4 62 15.902 62 30.5612C62 43.7852 52.4953 54.892 39.9009 56.8254V61.5784C39.9009 63.7191 38.1544 65.4546 36 65.4546C33.8456 65.4546 32.0991 63.7191 32.0991 61.5784V56.8254C19.5047 54.892 10 43.7852 10 30.5612Z" fill="#037B00"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 36.946 56.73 L 38.737 56.455 C 50.275 54.684 59.045 44.486 59.045 32.277 C 59.045 18.74 48.317 7.826 35.156 7.826 C 21.995 7.826 11.266 18.74 11.266 32.277 C 11.266 44.486 20.037 54.684 31.575 56.455 L 33.365 56.73 L 33.365 63.294 C 33.365 64.257 34.154 65.06 35.156 65.06 C 36.157 65.06 36.946 64.257 36.946 63.294 L 36.946 56.73 Z M 39.057 58.541 C 51.651 56.608 61.156 45.501 61.156 32.277 C 61.156 17.618 49.526 5.716 35.156 5.716 C 20.786 5.716 9.156 17.618 9.156 32.277 C 9.156 45.501 18.661 56.608 31.255 58.541 L 31.255 63.294 C 31.255 65.435 33.001 67.171 35.156 67.171 C 37.31 67.171 39.057 65.435 39.057 63.294 L 39.057 58.541 Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 32.852 41.317 C 31.77 41.317 30.893 42.194 30.893 43.276 C 30.893 44.358 31.77 45.235 32.852 45.235 C 33.933 45.235 34.81 44.358 34.81 43.276 C 34.81 42.194 33.933 41.317 32.852 41.317 Z M 29.466 43.276 C 29.466 41.406 30.982 39.891 32.852 39.891 C 34.721 39.891 36.237 41.406 36.237 43.276 C 36.237 45.145 34.721 46.661 32.852 46.661 C 30.982 46.661 29.466 45.145 29.466 43.276 Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 22.339 43.276 C 22.339 42.882 22.659 42.563 23.053 42.563 L 30.178 42.563 C 30.572 42.563 30.891 42.882 30.891 43.276 C 30.891 43.67 30.572 43.989 30.178 43.989 L 23.053 43.989 C 22.659 43.989 22.339 43.67 22.339 43.276 Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 22.339 28.134 C 22.339 27.74 22.659 27.421 23.053 27.421 L 27.086 27.421 C 28.705 27.421 30.186 28.337 30.91 29.785 C 30.91 29.785 30.91 29.786 30.91 29.785 L 33.291 34.547 L 36.413 34.547 C 38.774 34.547 40.689 36.461 40.689 38.822 L 40.689 41.494 C 40.689 42.872 39.572 43.989 38.194 43.989 L 35.522 43.989 C 35.128 43.989 34.809 43.67 34.809 43.276 C 34.809 42.882 35.128 42.563 35.522 42.563 L 38.194 42.563 C 38.784 42.563 39.262 42.084 39.262 41.494 L 39.262 38.822 C 39.262 37.249 37.987 35.973 36.413 35.973 L 32.85 35.973 C 32.58 35.973 32.333 35.82 32.212 35.579 L 29.634 30.423 C 29.152 29.458 28.165 28.848 27.086 28.847 C 27.086 28.847 27.086 28.847 27.086 28.847 L 23.053 28.847 C 22.659 28.847 22.339 28.528 22.339 28.134 Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 22.339 35.26 C 22.339 34.866 22.659 34.547 23.053 34.547 L 32.85 34.547 C 33.244 34.547 33.563 34.866 33.563 35.26 C 33.563 35.654 33.244 35.973 32.85 35.973 L 23.053 35.973 C 22.659 35.973 22.339 35.654 22.339 35.26 Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 48.058 25.705 C 48.058 26.099 47.739 26.418 47.345 26.418 L 36.819 26.418 C 36.425 26.418 36.106 26.099 36.106 25.705 C 36.106 25.311 36.425 24.992 36.819 24.992 L 47.345 24.992 C 47.739 24.992 48.058 25.311 48.058 25.705 Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 47.849 25.201 C 48.128 25.479 48.128 25.931 47.849 26.209 L 45.42 28.638 C 45.142 28.917 44.69 28.917 44.412 28.638 C 44.133 28.36 44.133 27.908 44.412 27.63 L 46.337 25.705 L 44.412 23.78 C 44.133 23.502 44.133 23.05 44.412 22.772 C 44.69 22.493 45.142 22.493 45.42 22.772 L 47.849 25.201 Z" fill="white"/>
</svg>
`;

const oneWayHoverIconSvgContent = (color, vehicleCount) => `
<svg viewBox="0 0 72 74" width="72px" height="74px" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
  <defs>
    <filter id="filter0_d_8000_25695" x="4" y="0" width="64" height="73.4546" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="3"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8000_25695"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8000_25695" result="shape"/>
    </filter>
    ${importFont()}
  </defs>
  <path opacity="0.2" d="M 35.503 69.756 C 41.143 69.756 45.715 68.232 45.715 66.352 C 45.715 64.472 41.143 62.948 35.503 62.948 C 29.862 62.948 25.29 64.472 25.29 66.352 C 25.29 68.232 29.862 69.756 35.503 69.756 Z" fill="#333333" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
  <g filter="url(#filter0_d_8000_25695)" transform="matrix(1, 0, 0, 1, -0.4972030222415915, 1.7562049627304086)">
    <path d="M10 30.5612C10 15.902 21.63 4 36 4C50.37 4 62 15.902 62 30.5612C62 43.7852 52.4953 54.892 39.9009 56.8254V61.5784C39.9009 63.7191 38.1544 65.4546 36 65.4546C33.8456 65.4546 32.0991 63.7191 32.0991 61.5784V56.8254C19.5047 54.892 10 43.7852 10 30.5612Z" fill="#037B00"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 37.293 56.771 L 39.083 56.496 C 50.622 54.724 59.392 44.527 59.392 32.317 C 59.392 18.78 48.664 7.867 35.503 7.867 C 22.342 7.867 11.613 18.78 11.613 32.317 C 11.613 44.527 20.384 54.724 31.922 56.496 L 33.712 56.771 L 33.712 63.335 C 33.712 64.297 34.501 65.1 35.503 65.1 C 36.504 65.1 37.293 64.297 37.293 63.335 L 37.293 56.771 Z M 39.404 58.582 C 51.998 56.648 61.503 45.541 61.503 32.317 C 61.503 17.658 49.873 5.756 35.503 5.756 C 21.133 5.756 9.503 17.658 9.503 32.317 C 9.503 45.541 19.007 56.648 31.602 58.582 L 31.602 63.335 C 31.602 65.475 33.348 67.211 35.503 67.211 C 37.657 67.211 39.404 65.475 39.404 63.335 L 39.404 58.582 Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 33.198 41.357 C 32.117 41.357 31.24 42.234 31.24 43.316 C 31.24 44.398 32.117 45.275 33.198 45.275 C 34.28 45.275 35.157 44.398 35.157 43.316 C 35.157 42.234 34.28 41.357 33.198 41.357 Z M 29.813 43.316 C 29.813 41.447 31.329 39.931 33.198 39.931 C 35.068 39.931 36.584 41.447 36.584 43.316 C 36.584 45.186 35.068 46.701 33.198 46.701 C 31.329 46.701 29.813 45.186 29.813 43.316 Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 22.686 43.316 C 22.686 42.922 23.006 42.603 23.399 42.603 L 30.525 42.603 C 30.919 42.603 31.238 42.922 31.238 43.316 C 31.238 43.71 30.919 44.029 30.525 44.029 L 23.399 44.029 C 23.006 44.029 22.686 43.71 22.686 43.316 Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 22.686 28.175 C 22.686 27.781 23.006 27.461 23.399 27.461 L 27.432 27.461 C 29.052 27.462 30.533 28.377 31.257 29.826 C 31.256 29.826 31.257 29.826 31.257 29.826 L 33.638 34.587 L 36.76 34.587 C 39.121 34.587 41.036 36.501 41.036 38.863 L 41.036 41.535 C 41.036 42.912 39.919 44.029 38.541 44.029 L 35.869 44.029 C 35.475 44.029 35.156 43.71 35.156 43.316 C 35.156 42.922 35.475 42.603 35.869 42.603 L 38.541 42.603 C 39.131 42.603 39.609 42.125 39.609 41.535 L 39.609 38.863 C 39.609 37.289 38.333 36.013 36.76 36.013 L 33.197 36.013 C 32.927 36.013 32.68 35.861 32.559 35.619 L 29.981 30.464 C 29.498 29.498 28.512 28.888 27.432 28.888 C 27.432 28.888 27.433 28.888 27.432 28.888 L 23.399 28.888 C 23.006 28.888 22.686 28.568 22.686 28.175 Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 22.686 35.3 C 22.686 34.906 23.006 34.587 23.399 34.587 L 33.197 34.587 C 33.591 34.587 33.91 34.906 33.91 35.3 C 33.91 35.694 33.591 36.013 33.197 36.013 L 23.399 36.013 C 23.006 36.013 22.686 35.694 22.686 35.3 Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 48.405 25.745 C 48.405 26.139 48.086 26.458 47.692 26.458 L 37.166 26.458 C 36.772 26.458 36.453 26.139 36.453 25.745 C 36.453 25.352 36.772 25.032 37.166 25.032 L 47.692 25.032 C 48.086 25.032 48.405 25.352 48.405 25.745 Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M 48.196 25.241 C 48.475 25.52 48.475 25.971 48.196 26.25 L 45.767 28.679 C 45.489 28.957 45.037 28.957 44.759 28.679 C 44.48 28.4 44.48 27.949 44.759 27.67 L 46.684 25.745 L 44.759 23.821 C 44.48 23.542 44.48 23.091 44.759 22.812 C 45.037 22.534 45.489 22.534 45.767 22.812 L 48.196 25.241 Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    ${circleBigIcons({ vehicleCount, opacity: 1 })}
</svg>
`;

export const disabledOneWay = color => oneWayIconSvgContent(color, 0);
export const disabledHoverOneWay = () => oneWayHoverIconSvgContent(0);
export const oneDigitOneWay = (color, vehicleCount = 0) => oneWayIconSvgContent(color, vehicleCount);
export const oneDigitHoverOneWay = (color, vehicleCount = 0) => oneWayHoverIconSvgContent(color, vehicleCount);
export const twoDigitsOneWay = (color, vehicleCount = 0) => oneWayIconSvgContent(color, vehicleCount);
export const twoDigitsHoverOneWay = (color, vehicleCount = 0) => oneWayHoverIconSvgContent(color, vehicleCount);

export const brand = () => brandIcon;

export const brandHover = () => brandHoverIcon;

export const businessBrand = () => `<svg width="64" height="74" viewBox="0 0 64 74" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path opacity="0.2" d="M31.9999 68C37.6401 68 42.2125 66.4759 42.2125 64.5958C42.2125 62.7158 37.6401 61.1917 31.9999 61.1917C26.3597 61.1917 21.7874 62.7158 21.7874 64.5958C21.7874 66.4759 26.3597 68 31.9999 68Z" fill="#333333"/>
  <g filter="url(#filter0_d_9019_1461)">
    <path d="M6 30.5612C6 15.902 17.63 4 32 4C46.37 4 58 15.902 58 30.5612C58 43.7852 48.4953 54.892 35.9009 56.8254V61.5784C35.9009 63.7191 34.1544 65.4546 32 65.4546C29.8456 65.4546 28.0991 63.7191 28.0991 61.5784V56.8254C15.5047 54.892 6 43.7852 6 30.5612Z" fill="#037B00"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M33.7905 55.0143L35.5807 54.7395C47.1188 52.9682 55.8896 42.7704 55.8896 30.5612C55.8896 17.0237 45.1611 6.11039 32 6.11039C18.8389 6.11039 8.11039 17.0237 8.11039 30.5612C8.11039 42.7704 16.8813 52.9682 28.4193 54.7395L30.2095 55.0143V61.5784C30.2095 62.541 30.9985 63.3442 32 63.3442C33.0015 63.3442 33.7905 62.541 33.7905 61.5784V55.0143ZM35.9009 56.8254C48.4953 54.892 58 43.7852 58 30.5612C58 15.902 46.37 4 32 4C17.63 4 6 15.902 6 30.5612C6 43.7852 15.5047 54.892 28.0991 56.8254V61.5784C28.0991 63.7191 29.8456 65.4546 32 65.4546C34.1544 65.4546 35.9009 63.7191 35.9009 61.5784V56.8254Z" fill="white"/>
  <g clip-path="url(#clip0_9019_1461)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5293 26.7856C20.5293 25.543 21.5367 24.5356 22.7793 24.5356H41.2079C42.4505 24.5356 43.4579 25.543 43.4579 26.7856V39.4999C43.4579 40.7426 42.4505 41.7499 41.2079 41.7499H22.7793C21.5367 41.7499 20.5293 40.7426 20.5293 39.4999V26.7856ZM22.7793 26.0356C22.3651 26.0356 22.0293 26.3714 22.0293 26.7856V39.4999C22.0293 39.9141 22.3651 40.2499 22.7793 40.2499H41.2079C41.6221 40.2499 41.9579 39.9141 41.9579 39.4999V26.7856C41.9579 26.3714 41.6221 26.0356 41.2079 26.0356H22.7793Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.8164 30.2856C24.8164 29.8714 25.1522 29.5356 25.5664 29.5356H38.4235C38.8378 29.5356 39.1735 29.8714 39.1735 30.2856C39.1735 30.6999 38.8378 31.0356 38.4235 31.0356H25.5664C25.1522 31.0356 24.8164 30.6999 24.8164 30.2856Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.8164 36C24.8164 35.5858 25.1522 35.25 25.5664 35.25H38.4235C38.8378 35.25 39.1735 35.5858 39.1735 36C39.1735 36.4142 38.8378 36.75 38.4235 36.75H25.5664C25.1522 36.75 24.8164 36.4142 24.8164 36Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.1671 20.25C29.2297 20.2499 28.3973 20.8495 28.1004 21.7387L30.1671 20.25ZM29.5232 22.2137C29.5232 22.2137 29.5233 22.2136 29.5232 22.2137L28.7495 24.5357H35.2399L34.4662 22.2137C34.3737 21.9367 34.1144 21.75 33.8224 21.75H30.1671C29.8751 21.75 29.6158 21.9368 29.5232 22.2137ZM30.1671 20.25H33.8222C33.8222 20.25 33.8223 20.25 33.8222 20.25C34.7596 20.2499 35.5921 20.8495 35.889 21.7387L36.992 25.0486C37.0682 25.2773 37.0298 25.5287 36.8888 25.7243C36.7479 25.9198 36.5215 26.0357 36.2804 26.0357H27.709C27.4679 26.0357 27.2415 25.9198 27.1006 25.7243C26.9596 25.5287 26.9212 25.2773 26.9975 25.0486L28.1004 21.7387" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.1367 23.1072C24.5509 23.1072 24.8867 23.443 24.8867 23.8572V25.2857C24.8867 25.7 24.5509 26.0357 24.1367 26.0357C23.7225 26.0357 23.3867 25.7 23.3867 25.2857V23.8572C23.3867 23.443 23.7225 23.1072 24.1367 23.1072Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.8516 23.1072C40.2658 23.1072 40.6016 23.443 40.6016 23.8572V25.2857C40.6016 25.7 40.2658 26.0357 39.8516 26.0357C39.4373 26.0357 39.1016 25.7 39.1016 25.2857V23.8572C39.1016 23.443 39.4373 23.1072 39.8516 23.1072Z" fill="white"/>
  </g>
  <defs>
    <filter id="filter0_d_9019_1461" x="0" y="0" width="64" height="73.4546" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="3"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9019_1461"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9019_1461" result="shape"/>
    </filter>
    <clipPath id="clip0_9019_1461">
      <rect width="32" height="32" fill="white" transform="translate(16 15)"/>
    </clipPath>
  </defs>
</svg>
`;

export const parking = () => parkingIcon;

export const pickupSelected = () => brandHoverIcon;

export const cityMarker = () => cityMarkerIcon;

export const dropOff = () => dropOffIcon;

export const dropOffSelected = () => dropOffSelectedIcon;

export const dropOffBusiness = () => dropOffBusinessIcon;

export const geoLocationOn = () => geoLocationIconOn;

export const geoLocationOff = () => geoLocationIconOff;

export const heartFavouriteLocation = () => heartFavouriteLocationIcon;

export const homeFavouriteLocation = () => homeFavouriteLocationIcon;

export const workFavouriteLocation = () => workFavouriteLocationIcon;

export const disabledBrand = (color, vehicleCount = 0) => `
  <svg viewBox="0 0 56 61" width="56px" height="61px" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
    <defs>
      <filter id="filter0_d_10339_7137" x="1.96924" y="4.40002" width="48.96" height="55.6801" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10339_7137"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10339_7137" result="shape"/>
      </filter>
      ${importFont()}
    </defs>
    <g opacity="0.5">
      <g filter="url(#filter0_d_10339_7137)">
        <path d="M7.96924 27.2789C7.96924 16.8596 16.2355 8.40002 26.4492 8.40002C36.663 8.40002 44.9292 16.8596 44.9292 27.2789C44.9292 36.6781 38.1736 44.5725 29.2219 45.9467V49.325C29.2219 50.8465 27.9805 52.08 26.4492 52.08C24.918 52.08 23.6766 50.8465 23.6766 49.325V45.9467C14.7249 44.5725 7.96924 36.6781 7.96924 27.2789Z" fill="#009900"/>
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7219 44.6594L28.9943 44.4641C37.1952 43.2051 43.4292 35.9568 43.4292 27.2789C43.4292 17.6569 35.8037 9.90002 26.4492 9.90002C17.0947 9.90002 9.46924 17.6569 9.46924 27.2789C9.46924 35.9568 15.7033 43.2051 23.9042 44.4641L25.1766 44.6594V49.325C25.1766 50.0091 25.7374 50.58 26.4492 50.58C27.1611 50.58 27.7219 50.0091 27.7219 49.325V44.6594ZM29.2219 45.9467C38.1736 44.5725 44.9292 36.6781 44.9292 27.2789C44.9292 16.8596 36.663 8.40002 26.4492 8.40002C16.2355 8.40002 7.96924 16.8596 7.96924 27.2789C7.96924 36.6781 14.7249 44.5725 23.6766 45.9467V49.325C23.6766 50.8465 24.918 52.08 26.4492 52.08C27.9805 52.08 29.2219 50.8465 29.2219 49.325V45.9467Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1807 30.3745V31.7994C19.1807 32.1515 19.4661 32.437 19.8183 32.437C20.1705 32.437 20.4559 32.1515 20.4559 31.7994V30.3745H21.5809V31.7994C21.5809 32.7729 20.7918 33.562 19.8183 33.562C18.8448 33.562 18.0557 32.7728 18.0557 31.7994V30.3745H19.1807Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M33.7178 30.3745V31.7994C33.7178 32.1515 33.4323 32.437 33.0801 32.437C32.728 32.437 32.4425 32.1515 32.4425 31.7994V30.3745H31.3175V31.7994C31.3175 32.7729 32.1067 33.562 33.0801 33.562C34.0536 33.562 34.8428 32.7728 34.8428 31.7994V30.3745H33.7178Z" fill="white"/>
      <path d="M20.9983 27.8972C20.9983 28.3632 20.6205 28.741 20.1545 28.741C19.6886 28.741 19.3108 28.3632 19.3108 27.8972C19.3108 27.4312 19.6886 27.0535 20.1545 27.0535C20.6205 27.0535 20.9983 27.4312 20.9983 27.8972Z" fill="white"/>
      <path d="M31.9001 27.8972C31.9001 28.3632 32.2779 28.741 32.7439 28.741C33.2099 28.741 33.5876 28.3632 33.5876 27.8972C33.5876 27.4312 33.2099 27.0535 32.7439 27.0535C32.2779 27.0535 31.9001 27.4312 31.9001 27.8972Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4463 27.6327C17.4463 25.9758 18.7894 24.6327 20.4463 24.6327H32.4521C34.109 24.6327 35.4521 25.9758 35.4521 27.6327V29.2868C35.4521 30.3224 34.6127 31.1618 33.5771 31.1618H19.3213C18.2858 31.1618 17.4463 30.3224 17.4463 29.2868V27.6327ZM20.4463 25.7577C19.4108 25.7577 18.5713 26.5972 18.5713 27.6327V29.2868C18.5713 29.701 18.9071 30.0368 19.3213 30.0368H33.5771C33.9914 30.0368 34.3271 29.701 34.3271 29.2868V27.6327C34.3271 26.5972 33.4877 25.7577 32.4521 25.7577H20.4463Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8277 21.563C22.2966 21.563 21.8179 21.883 21.6149 22.3738L20.219 25.7481L19.1794 25.3181L20.5753 21.9437C20.9523 21.0324 21.8414 20.438 22.8277 20.438H30.0708C31.057 20.438 31.9461 21.0324 32.3231 21.9437L33.719 25.3181L32.6794 25.7481L31.2836 22.3738C31.0806 21.883 30.6018 21.563 30.0708 21.563H22.8277Z" fill="white"/>
    </g>
    ${circleSmallIcons({ vehicleCount, opacity: 0.5 })}
  </svg>
`;

export const disabledHoverBrand = (color, vehicleCount = 0) => `
<svg viewBox="0 0 72 74" width="72px" height="74px" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
  <defs>
    <filter id="filter0_d_8000_25653" x="4" y="0" width="64" height="73.4546" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="3"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8000_25653"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8000_25653" result="shape"/>
    </filter>
    ${importFont()}
  </defs>
  <path d="M35.9999 68C41.6401 68 46.2125 66.4759 46.2125 64.5958C46.2125 62.7158 41.6401 61.1917 35.9999 61.1917C30.3597 61.1917 25.7874 62.7158 25.7874 64.5958C25.7874 66.4759 30.3597 68 35.9999 68Z" style="paint-order: stroke; opacity: 0.4;" fill="#333333" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"/>
  <g filter="url(#filter0_d_8000_25653)" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)" style="opacity: 0.5;">
    <path d="M10 30.5612C10 15.902 21.63 4 36 4C50.37 4 62 15.902 62 30.5612C62 43.7852 52.4953 54.892 39.9009 56.8254V61.5784C39.9009 63.7191 38.1544 65.4546 36 65.4546C33.8456 65.4546 32.0991 63.7191 32.0991 61.5784V56.8254C19.5047 54.892 10 43.7852 10 30.5612Z" style="paint-order: stroke;" fill="#037B00"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M37.7905 55.0143L39.5807 54.7395C51.1188 52.9682 59.8896 42.7704 59.8896 30.5612C59.8896 17.0237 49.1611 6.11039 36 6.11039C22.8389 6.11039 12.1104 17.0237 12.1104 30.5612C12.1104 42.7704 20.8813 52.9682 32.4193 54.7395L34.2095 55.0143V61.5784C34.2095 62.541 34.9985 63.3442 36 63.3442C37.0015 63.3442 37.7905 62.541 37.7905 61.5784V55.0143ZM39.9009 56.8254C52.4953 54.892 62 43.7852 62 30.5612C62 15.902 50.37 4 36 4C21.63 4 10 15.902 10 30.5612C10 43.7852 19.5047 54.892 32.0991 56.8254V61.5784C32.0991 63.7191 33.8456 65.4546 36 65.4546C38.1544 65.4546 39.9009 63.7191 39.9009 61.5784V56.8254Z" style="paint-order: stroke;" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3086 35.4993V37.3991C26.3086 37.8686 26.6892 38.2493 27.1588 38.2493C27.6283 38.2493 28.009 37.8686 28.009 37.3991V35.4993H29.509V37.3991C29.509 38.6971 28.4568 39.7493 27.1588 39.7493C25.8608 39.7493 24.8086 38.6971 24.8086 37.3991V35.4993H26.3086Z" style="paint-order: stroke;" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M45.6914 35.4993V37.3991C45.6914 37.8686 45.3108 38.2493 44.8412 38.2493C44.3717 38.2493 43.991 37.8686 43.991 37.3991V35.4993H42.491V37.3991C42.491 38.6971 43.5432 39.7493 44.8412 39.7493C46.1392 39.7493 47.1914 38.6971 47.1914 37.3991V35.4993H45.6914Z" style="paint-order: stroke;" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"/>
  <path d="M28.7319 32.1963C28.7319 32.8176 28.2283 33.3213 27.6069 33.3213C26.9856 33.3213 26.4819 32.8176 26.4819 32.1963C26.4819 31.575 26.9856 31.0713 27.6069 31.0713C28.2283 31.0713 28.7319 31.575 28.7319 32.1963Z" style="paint-order: stroke;" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"/>
  <path d="M43.2681 32.1963C43.2681 32.8176 43.7717 33.3213 44.3931 33.3213C45.0144 33.3213 45.5181 32.8176 45.5181 32.1963C45.5181 31.575 45.0144 31.0713 44.3931 31.0713C43.7717 31.0713 43.2681 31.575 43.2681 32.1963Z" style="paint-order: stroke;" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9961 31.8435C23.9961 29.6344 25.787 27.8435 27.9961 27.8435H44.0039C46.213 27.8435 48.0039 29.6344 48.0039 31.8435V34.049C48.0039 35.4297 46.8846 36.549 45.5039 36.549H26.4961C25.1154 36.549 23.9961 35.4297 23.9961 34.049V31.8435ZM27.9961 29.3435C26.6154 29.3435 25.4961 30.4628 25.4961 31.8435V34.049C25.4961 34.6013 25.9438 35.049 26.4961 35.049H45.5039C46.0562 35.049 46.5039 34.6013 46.5039 34.049V31.8435C46.5039 30.4628 45.3846 29.3435 44.0039 29.3435H27.9961Z" style="paint-order: stroke;" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1712 23.7507C30.4631 23.7507 29.8248 24.1775 29.5541 24.8318L27.693 29.3309L26.3069 28.7576L28.168 24.2584C28.6707 23.0432 29.8562 22.2507 31.1712 22.2507H40.8286C42.1437 22.2507 43.3291 23.0432 43.8318 24.2584L45.693 28.7576L44.3069 29.3309L42.4457 24.8318C42.1751 24.1775 41.5367 23.7507 40.8286 23.7507H31.1712Z" style="paint-order: stroke;" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 0)"/>
   ${circleBigIcons({ vehicleCount, opacity: 0.5 })}
</svg>
`;

export const oneDigitBrand = (color, vehicleCount) => `
<svg viewBox="0 0 56 61" width="56px" height="61px" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
  <defs>
    <filter id="filter0_d_8000_25640" x="1.96924" y="4.40002" width="48.96" height="55.6801" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="3"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8000_25640"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8000_25640" result="shape"/>
    </filter>
    ${importFont()}
  </defs>
  <g filter="url(#filter0_d_8000_25640)">
    <path d="M7.96924 27.2789C7.96924 16.8596 16.2355 8.40002 26.4492 8.40002C36.663 8.40002 44.9292 16.8596 44.9292 27.2789C44.9292 36.6781 38.1736 44.5725 29.2219 45.9467V49.325C29.2219 50.8465 27.9805 52.08 26.4492 52.08C24.918 52.08 23.6766 50.8465 23.6766 49.325V45.9467C14.7249 44.5725 7.96924 36.6781 7.96924 27.2789Z" fill="#009900"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7219 44.6594L28.9943 44.4641C37.1952 43.2051 43.4292 35.9568 43.4292 27.2789C43.4292 17.6569 35.8037 9.90002 26.4492 9.90002C17.0947 9.90002 9.46924 17.6569 9.46924 27.2789C9.46924 35.9568 15.7033 43.2051 23.9042 44.4641L25.1766 44.6594V49.325C25.1766 50.0091 25.7374 50.58 26.4492 50.58C27.1611 50.58 27.7219 50.0091 27.7219 49.325V44.6594ZM29.2219 45.9467C38.1736 44.5725 44.9292 36.6781 44.9292 27.2789C44.9292 16.8596 36.663 8.40002 26.4492 8.40002C16.2355 8.40002 7.96924 16.8596 7.96924 27.2789C7.96924 36.6781 14.7249 44.5725 23.6766 45.9467V49.325C23.6766 50.8465 24.918 52.08 26.4492 52.08C27.9805 52.08 29.2219 50.8465 29.2219 49.325V45.9467Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1807 30.3745V31.7994C19.1807 32.1515 19.4661 32.437 19.8183 32.437C20.1705 32.437 20.4559 32.1515 20.4559 31.7994V30.3745H21.5809V31.7994C21.5809 32.7729 20.7918 33.562 19.8183 33.562C18.8448 33.562 18.0557 32.7728 18.0557 31.7994V30.3745H19.1807Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M33.7178 30.3745V31.7994C33.7178 32.1515 33.4323 32.437 33.0801 32.437C32.728 32.437 32.4425 32.1515 32.4425 31.7994V30.3745H31.3175V31.7994C31.3175 32.7729 32.1067 33.562 33.0801 33.562C34.0536 33.562 34.8428 32.7728 34.8428 31.7994V30.3745H33.7178Z" fill="white"/>
  <path d="M20.9983 27.8972C20.9983 28.3632 20.6205 28.741 20.1545 28.741C19.6886 28.741 19.3108 28.3632 19.3108 27.8972C19.3108 27.4312 19.6886 27.0535 20.1545 27.0535C20.6205 27.0535 20.9983 27.4312 20.9983 27.8972Z" fill="white"/>
  <path d="M31.9001 27.8972C31.9001 28.3632 32.2779 28.741 32.7439 28.741C33.2099 28.741 33.5876 28.3632 33.5876 27.8972C33.5876 27.4312 33.2099 27.0535 32.7439 27.0535C32.2779 27.0535 31.9001 27.4312 31.9001 27.8972Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4463 27.6327C17.4463 25.9758 18.7894 24.6327 20.4463 24.6327H32.4521C34.109 24.6327 35.4521 25.9758 35.4521 27.6327V29.2868C35.4521 30.3224 34.6127 31.1618 33.5771 31.1618H19.3213C18.2858 31.1618 17.4463 30.3224 17.4463 29.2868V27.6327ZM20.4463 25.7577C19.4108 25.7577 18.5713 26.5972 18.5713 27.6327V29.2868C18.5713 29.701 18.9071 30.0368 19.3213 30.0368H33.5771C33.9914 30.0368 34.3271 29.701 34.3271 29.2868V27.6327C34.3271 26.5972 33.4877 25.7577 32.4521 25.7577H20.4463Z" fill="white"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8277 21.563C22.2966 21.563 21.8179 21.883 21.6149 22.3738L20.219 25.7481L19.1794 25.3181L20.5753 21.9437C20.9523 21.0324 21.8414 20.438 22.8277 20.438H30.0708C31.057 20.438 31.9461 21.0324 32.3231 21.9437L33.719 25.3181L32.6794 25.7481L31.2836 22.3738C31.0806 21.883 30.6018 21.563 30.0708 21.563H22.8277Z" fill="white"/>
   ${circleSmallIcons({ vehicleCount, color: '#009900', opacity: 1 })}
</svg>
`;

export const oneDigitHoverBrand = (color, vehicleCount) => `
  <svg viewBox="0 0 72 74" width="72px" height="74px" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
    <defs>
      <filter id="filter0_d_8000_25653" x="4" y="0" width="64" height="73.4546" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8000_25653"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8000_25653" result="shape"/>
      </filter>
      ${importFont()}
    </defs>
    <path opacity="0.2" d="M35.9999 68C41.6401 68 46.2125 66.4759 46.2125 64.5958C46.2125 62.7158 41.6401 61.1917 35.9999 61.1917C30.3597 61.1917 25.7874 62.7158 25.7874 64.5958C25.7874 66.4759 30.3597 68 35.9999 68Z" fill="#333333" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <g filter="url(#filter0_d_8000_25653)" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)">
      <path d="M10 30.5612C10 15.902 21.63 4 36 4C50.37 4 62 15.902 62 30.5612C62 43.7852 52.4953 54.892 39.9009 56.8254V61.5784C39.9009 63.7191 38.1544 65.4546 36 65.4546C33.8456 65.4546 32.0991 63.7191 32.0991 61.5784V56.8254C19.5047 54.892 10 43.7852 10 30.5612Z" fill="#037B00"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.7905 55.0143L39.5807 54.7395C51.1188 52.9682 59.8896 42.7704 59.8896 30.5612C59.8896 17.0237 49.1611 6.11039 36 6.11039C22.8389 6.11039 12.1104 17.0237 12.1104 30.5612C12.1104 42.7704 20.8813 52.9682 32.4193 54.7395L34.2095 55.0143V61.5784C34.2095 62.541 34.9985 63.3442 36 63.3442C37.0015 63.3442 37.7905 62.541 37.7905 61.5784V55.0143ZM39.9009 56.8254C52.4953 54.892 62 43.7852 62 30.5612C62 15.902 50.37 4 36 4C21.63 4 10 15.902 10 30.5612C10 43.7852 19.5047 54.892 32.0991 56.8254V61.5784C32.0991 63.7191 33.8456 65.4546 36 65.4546C38.1544 65.4546 39.9009 63.7191 39.9009 61.5784V56.8254Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3086 35.4993V37.3991C26.3086 37.8686 26.6892 38.2493 27.1588 38.2493C27.6283 38.2493 28.009 37.8686 28.009 37.3991V35.4993H29.509V37.3991C29.509 38.6971 28.4568 39.7493 27.1588 39.7493C25.8608 39.7493 24.8086 38.6971 24.8086 37.3991V35.4993H26.3086Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M45.6914 35.4993V37.3991C45.6914 37.8686 45.3108 38.2493 44.8412 38.2493C44.3717 38.2493 43.991 37.8686 43.991 37.3991V35.4993H42.491V37.3991C42.491 38.6971 43.5432 39.7493 44.8412 39.7493C46.1392 39.7493 47.1914 38.6971 47.1914 37.3991V35.4993H45.6914Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <path d="M28.7319 32.1963C28.7319 32.8176 28.2283 33.3213 27.6069 33.3213C26.9856 33.3213 26.4819 32.8176 26.4819 32.1963C26.4819 31.575 26.9856 31.0713 27.6069 31.0713C28.2283 31.0713 28.7319 31.575 28.7319 32.1963Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <path d="M43.2681 32.1963C43.2681 32.8176 43.7717 33.3213 44.3931 33.3213C45.0144 33.3213 45.5181 32.8176 45.5181 32.1963C45.5181 31.575 45.0144 31.0713 44.3931 31.0713C43.7717 31.0713 43.2681 31.575 43.2681 32.1963Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9961 31.8435C23.9961 29.6344 25.787 27.8435 27.9961 27.8435H44.0039C46.213 27.8435 48.0039 29.6344 48.0039 31.8435V34.049C48.0039 35.4297 46.8846 36.549 45.5039 36.549H26.4961C25.1154 36.549 23.9961 35.4297 23.9961 34.049V31.8435ZM27.9961 29.3435C26.6154 29.3435 25.4961 30.4628 25.4961 31.8435V34.049C25.4961 34.6013 25.9438 35.049 26.4961 35.049H45.5039C46.0562 35.049 46.5039 34.6013 46.5039 34.049V31.8435C46.5039 30.4628 45.3846 29.3435 44.0039 29.3435H27.9961Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1712 23.7507C30.4631 23.7507 29.8248 24.1775 29.5541 24.8318L27.693 29.3309L26.3069 28.7576L28.168 24.2584C28.6707 23.0432 29.8562 22.2507 31.1712 22.2507H40.8286C42.1437 22.2507 43.3291 23.0432 43.8318 24.2584L45.693 28.7576L44.3069 29.3309L42.4457 24.8318C42.1751 24.1775 41.5367 23.7507 40.8286 23.7507H31.1712Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
      ${circleBigIcons({ vehicleCount, color: '#009900', opacity: 1 })}
  </svg>
`;

export const twoDigitsBrand = (color, vehicleCount) => `
<svg viewBox="0 0 56 61" width="56px" height="61px" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
    <defs>
      <filter id="filter0_d_8000_25640" x="1.96924" y="4.40002" width="48.96" height="55.6801" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8000_25640"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8000_25640" result="shape"/>
      </filter>
      ${importFont()}
    </defs>
    <g filter="url(#filter0_d_8000_25640)">
      <path d="M7.96924 27.2789C7.96924 16.8596 16.2355 8.40002 26.4492 8.40002C36.663 8.40002 44.9292 16.8596 44.9292 27.2789C44.9292 36.6781 38.1736 44.5725 29.2219 45.9467V49.325C29.2219 50.8465 27.9805 52.08 26.4492 52.08C24.918 52.08 23.6766 50.8465 23.6766 49.325V45.9467C14.7249 44.5725 7.96924 36.6781 7.96924 27.2789Z" fill="#009900"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7219 44.6594L28.9943 44.4641C37.1952 43.2051 43.4292 35.9568 43.4292 27.2789C43.4292 17.6569 35.8037 9.90002 26.4492 9.90002C17.0947 9.90002 9.46924 17.6569 9.46924 27.2789C9.46924 35.9568 15.7033 43.2051 23.9042 44.4641L25.1766 44.6594V49.325C25.1766 50.0091 25.7374 50.58 26.4492 50.58C27.1611 50.58 27.7219 50.0091 27.7219 49.325V44.6594ZM29.2219 45.9467C38.1736 44.5725 44.9292 36.6781 44.9292 27.2789C44.9292 16.8596 36.663 8.40002 26.4492 8.40002C16.2355 8.40002 7.96924 16.8596 7.96924 27.2789C7.96924 36.6781 14.7249 44.5725 23.6766 45.9467V49.325C23.6766 50.8465 24.918 52.08 26.4492 52.08C27.9805 52.08 29.2219 50.8465 29.2219 49.325V45.9467Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1807 30.3745V31.7994C19.1807 32.1515 19.4661 32.437 19.8183 32.437C20.1705 32.437 20.4559 32.1515 20.4559 31.7994V30.3745H21.5809V31.7994C21.5809 32.7729 20.7918 33.562 19.8183 33.562C18.8448 33.562 18.0557 32.7728 18.0557 31.7994V30.3745H19.1807Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.7178 30.3745V31.7994C33.7178 32.1515 33.4323 32.437 33.0801 32.437C32.728 32.437 32.4425 32.1515 32.4425 31.7994V30.3745H31.3175V31.7994C31.3175 32.7729 32.1067 33.562 33.0801 33.562C34.0536 33.562 34.8428 32.7728 34.8428 31.7994V30.3745H33.7178Z" fill="white"/>
    <path d="M20.9983 27.8972C20.9983 28.3632 20.6205 28.741 20.1545 28.741C19.6886 28.741 19.3108 28.3632 19.3108 27.8972C19.3108 27.4312 19.6886 27.0535 20.1545 27.0535C20.6205 27.0535 20.9983 27.4312 20.9983 27.8972Z" fill="white"/>
    <path d="M31.9001 27.8972C31.9001 28.3632 32.2779 28.741 32.7439 28.741C33.2099 28.741 33.5876 28.3632 33.5876 27.8972C33.5876 27.4312 33.2099 27.0535 32.7439 27.0535C32.2779 27.0535 31.9001 27.4312 31.9001 27.8972Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4463 27.6327C17.4463 25.9758 18.7894 24.6327 20.4463 24.6327H32.4521C34.109 24.6327 35.4521 25.9758 35.4521 27.6327V29.2868C35.4521 30.3224 34.6127 31.1618 33.5771 31.1618H19.3213C18.2858 31.1618 17.4463 30.3224 17.4463 29.2868V27.6327ZM20.4463 25.7577C19.4108 25.7577 18.5713 26.5972 18.5713 27.6327V29.2868C18.5713 29.701 18.9071 30.0368 19.3213 30.0368H33.5771C33.9914 30.0368 34.3271 29.701 34.3271 29.2868V27.6327C34.3271 26.5972 33.4877 25.7577 32.4521 25.7577H20.4463Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8277 21.563C22.2966 21.563 21.8179 21.883 21.6149 22.3738L20.219 25.7481L19.1794 25.3181L20.5753 21.9437C20.9523 21.0324 21.8414 20.438 22.8277 20.438H30.0708C31.057 20.438 31.9461 21.0324 32.3231 21.9437L33.719 25.3181L32.6794 25.7481L31.2836 22.3738C31.0806 21.883 30.6018 21.563 30.0708 21.563H22.8277Z" fill="white"/>
      ${circleSmallIcons({ vehicleCount, color: '#009900', opacity: 1 })}
  </svg>
`;

export const twoDigitsHoverBrand = (color, vehicleCount) => `
<svg viewBox="0 0 72 74" width="72px" height="74px" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
    <defs>
      <filter id="filter0_d_8000_25653" x="4" y="0" width="64" height="73.4546" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8000_25653"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8000_25653" result="shape"/>
      </filter>
      ${importFont()}
    </defs>
    <path opacity="0.2" d="M35.9999 68C41.6401 68 46.2125 66.4759 46.2125 64.5958C46.2125 62.7158 41.6401 61.1917 35.9999 61.1917C30.3597 61.1917 25.7874 62.7158 25.7874 64.5958C25.7874 66.4759 30.3597 68 35.9999 68Z" fill="#333333" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <g filter="url(#filter0_d_8000_25653)" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)">
      <path d="M10 30.5612C10 15.902 21.63 4 36 4C50.37 4 62 15.902 62 30.5612C62 43.7852 52.4953 54.892 39.9009 56.8254V61.5784C39.9009 63.7191 38.1544 65.4546 36 65.4546C33.8456 65.4546 32.0991 63.7191 32.0991 61.5784V56.8254C19.5047 54.892 10 43.7852 10 30.5612Z" fill="#037B00"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.7905 55.0143L39.5807 54.7395C51.1188 52.9682 59.8896 42.7704 59.8896 30.5612C59.8896 17.0237 49.1611 6.11039 36 6.11039C22.8389 6.11039 12.1104 17.0237 12.1104 30.5612C12.1104 42.7704 20.8813 52.9682 32.4193 54.7395L34.2095 55.0143V61.5784C34.2095 62.541 34.9985 63.3442 36 63.3442C37.0015 63.3442 37.7905 62.541 37.7905 61.5784V55.0143ZM39.9009 56.8254C52.4953 54.892 62 43.7852 62 30.5612C62 15.902 50.37 4 36 4C21.63 4 10 15.902 10 30.5612C10 43.7852 19.5047 54.892 32.0991 56.8254V61.5784C32.0991 63.7191 33.8456 65.4546 36 65.4546C38.1544 65.4546 39.9009 63.7191 39.9009 61.5784V56.8254Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3086 35.4993V37.3991C26.3086 37.8686 26.6892 38.2493 27.1588 38.2493C27.6283 38.2493 28.009 37.8686 28.009 37.3991V35.4993H29.509V37.3991C29.509 38.6971 28.4568 39.7493 27.1588 39.7493C25.8608 39.7493 24.8086 38.6971 24.8086 37.3991V35.4993H26.3086Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M45.6914 35.4993V37.3991C45.6914 37.8686 45.3108 38.2493 44.8412 38.2493C44.3717 38.2493 43.991 37.8686 43.991 37.3991V35.4993H42.491V37.3991C42.491 38.6971 43.5432 39.7493 44.8412 39.7493C46.1392 39.7493 47.1914 38.6971 47.1914 37.3991V35.4993H45.6914Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <path d="M28.7319 32.1963C28.7319 32.8176 28.2283 33.3213 27.6069 33.3213C26.9856 33.3213 26.4819 32.8176 26.4819 32.1963C26.4819 31.575 26.9856 31.0713 27.6069 31.0713C28.2283 31.0713 28.7319 31.575 28.7319 32.1963Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <path d="M43.2681 32.1963C43.2681 32.8176 43.7717 33.3213 44.3931 33.3213C45.0144 33.3213 45.5181 32.8176 45.5181 32.1963C45.5181 31.575 45.0144 31.0713 44.3931 31.0713C43.7717 31.0713 43.2681 31.575 43.2681 32.1963Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9961 31.8435C23.9961 29.6344 25.787 27.8435 27.9961 27.8435H44.0039C46.213 27.8435 48.0039 29.6344 48.0039 31.8435V34.049C48.0039 35.4297 46.8846 36.549 45.5039 36.549H26.4961C25.1154 36.549 23.9961 35.4297 23.9961 34.049V31.8435ZM27.9961 29.3435C26.6154 29.3435 25.4961 30.4628 25.4961 31.8435V34.049C25.4961 34.6013 25.9438 35.049 26.4961 35.049H45.5039C46.0562 35.049 46.5039 34.6013 46.5039 34.049V31.8435C46.5039 30.4628 45.3846 29.3435 44.0039 29.3435H27.9961Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1712 23.7507C30.4631 23.7507 29.8248 24.1775 29.5541 24.8318L27.693 29.3309L26.3069 28.7576L28.168 24.2584C28.6707 23.0432 29.8562 22.2507 31.1712 22.2507H40.8286C42.1437 22.2507 43.3291 23.0432 43.8318 24.2584L45.693 28.7576L44.3069 29.3309L42.4457 24.8318C42.1751 24.1775 41.5367 23.7507 40.8286 23.7507H31.1712Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
      ${circleBigIcons({ vehicleCount, color: '#009900', opacity: 1 })}
  </svg>
`;

export const disabledBusiness = (color, vehicleCount = 0) => `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="50" viewBox="0 0 43 50" xmlns:bx="https://boxy-svg.com">
    <defs>
        <path id="b" d="M10.87 31.16C4.55 29.022 0 23.042 0 16 0 7.163 7.163 0 16 0s16 7.163 16 16c0 7.042-4.55 13.022-10.87 15.16L16 38l-5.13-6.84z"/>
        <filter id="a" width="146.9%" height="141.2%" x="-23.4%" y="-17.1%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius="1" result="shadowSpreadOuter1"/>
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        </filter>
        ${importFont()}
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g transform="translate(5 6)">
            <use fill="#000" filter="url(#a)" xlink:href="#b"/>
            <path opacity="0.5" fill="#009900" stroke="#FFF" d="M10.562 31.583A16.506 16.506 0 0 1-.5 16C-.5 6.887 6.887-.5 16-.5S32.5 6.887 32.5 16a16.506 16.506 0 0 1-11.062 15.583L16 38.833l-5.438-7.25z"/>
        </g>
        <g transform="translate(5, 6)">
            <path d="M13.7142857,11.8461538 L13.7142857,10 L19.2857143,10 L19.2857143,11.8461538 L21.137417,11.8461538 C22.1660932,11.8461538 23,12.6770857 23,13.6849388 L23,20.1612151 C23,21.1767479 22.1670156,22 21.137417,22 L11.862583,22 C10.8339068,22 10,21.1690682 10,20.1612151 L10,13.6849388 C10,12.6694059 10.8329844,11.8461538 11.862583,11.8461538 L13.7142857,11.8461538 Z M14.6428571,10.9230769 L14.6428571,11.8461538 L18.3571429,11.8461538 L18.3571429,10.9230769 L14.6428571,10.9230769 Z" id="Combined-Shape" fill="#FFFFFF"></path>
        </g>
        <g transform="translate(27)">
            <circle opacity="0.5" cx="8" cy="8" r="7.5" fill="#FFF" stroke="#009900"/>
            <text opacity="0.5" fill="#333" style="font-family: 'NEXT Book';" font-size="12" font-weight="300">
                <tspan x="5.5" y="11">${vehicleCount}</tspan>
            </text>
        </g>
    </g>
</svg>
`;

export const disabledHoverBusiness = (color, vehicleCount = 0) => `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="58" height="66" viewBox="0 0 58 66" xmlns:bx="https://boxy-svg.com">
    <defs>
        <path id="b" d="M17.087 43.45C7.302 41.216 0 32.46 0 22 0 9.85 9.85 0 22 0s22 9.85 22 22c0 10.461-7.302 19.217-17.087 21.45L22 50l-4.913-6.55z"/>
        <filter id="a" width="143.2%" height="142%" x="-21.6%" y="-17%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius="3" result="shadowSpreadOuter1"/>
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        </filter>
        ${importFont()}
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(7 3)">
        <g transform="translate(0 3)">
            <ellipse cx="22" cy="52" fill="#333" fill-opacity=".2" fill-rule="nonzero" rx="10" ry="5"/>
            <use fill="#000" filter="url(#a)" xlink:href="#b"/>
            <path opacity="0.5" fill="#009900" stroke="#FFF" stroke-width="3" d="M16.211 44.781C5.888 42.168-1.5 32.833-1.5 22-1.5 9.021 9.021-1.5 22-1.5S45.5 9.021 45.5 22c0 10.833-7.388 20.168-17.711 22.781L22 52.5l-5.789-7.719z"/>
        </g>
        <g transform="translate(-2, 1) scale(1.50)">
            <path d="M13.7142857,11.8461538 L13.7142857,10 L19.2857143,10 L19.2857143,11.8461538 L21.137417,11.8461538 C22.1660932,11.8461538 23,12.6770857 23,13.6849388 L23,20.1612151 C23,21.1767479 22.1670156,22 21.137417,22 L11.862583,22 C10.8339068,22 10,21.1690682 10,20.1612151 L10,13.6849388 C10,12.6694059 10.8329844,11.8461538 11.862583,11.8461538 L13.7142857,11.8461538 Z M14.6428571,10.9230769 L14.6428571,11.8461538 L18.3571429,11.8461538 L18.3571429,10.9230769 L14.6428571,10.9230769 Z" id="Combined-Shape" fill="#FFFFFF"></path>
        </g>
        <circle opacity="0.5" cx="39" cy="8" r="7.5" fill="#FFF" stroke="#009900"/>
        <text opacity="0.5" fill="#333" style="font-family: 'NEXT Book';" font-size="12" font-weight="300">
            <tspan x="37" y="10.5">${vehicleCount}</tspan>
        </text>
    </g>
</svg>
`;

export const oneDigitBusiness = (color, vehicleCount) => `
  <svg viewBox="0 0 56 61" width="56px" height="61px" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
    <defs>
      <filter id="filter0_d_8000_25746" x="1.96924" y="4.40002" width="48.96" height="55.6801" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8000_25746"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8000_25746" result="shape"/>
      </filter>
      <clipPath id="clip0_8000_25746">
        <rect width="24" height="24" fill="white" transform="translate(14.4492 15)"/>
      </clipPath>
    </defs>
    <g filter="url(#filter0_d_8000_25746)">
      <path d="M7.96924 27.2789C7.96924 16.8596 16.2355 8.40002 26.4492 8.40002C36.663 8.40002 44.9292 16.8596 44.9292 27.2789C44.9292 36.6781 38.1736 44.5725 29.2219 45.9467V49.325C29.2219 50.8465 27.9805 52.08 26.4492 52.08C24.918 52.08 23.6766 50.8465 23.6766 49.325V45.9467C14.7249 44.5725 7.96924 36.6781 7.96924 27.2789Z" fill="#009900"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7219 44.6594L28.9943 44.4641C37.1952 43.2051 43.4292 35.9568 43.4292 27.2789C43.4292 17.6569 35.8037 9.90002 26.4492 9.90002C17.0947 9.90002 9.46924 17.6569 9.46924 27.2789C9.46924 35.9568 15.7033 43.2051 23.9042 44.4641L25.1766 44.6594V49.325C25.1766 50.0091 25.7374 50.58 26.4492 50.58C27.1611 50.58 27.7219 50.0091 27.7219 49.325V44.6594ZM29.2219 45.9467C38.1736 44.5725 44.9292 36.6781 44.9292 27.2789C44.9292 16.8596 36.663 8.40002 26.4492 8.40002C16.2355 8.40002 7.96924 16.8596 7.96924 27.2789C7.96924 36.6781 14.7249 44.5725 23.6766 45.9467V49.325C23.6766 50.8465 24.918 52.08 26.4492 52.08C27.9805 52.08 29.2219 50.8465 29.2219 49.325V45.9467Z" fill="white"/>
    <g clip-path="url(#clip0_8000_25746)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8477 23.8392C17.8477 22.9073 18.6032 22.1517 19.5352 22.1517H33.3566C34.2886 22.1517 35.0441 22.9073 35.0441 23.8392V33.3749C35.0441 34.3069 34.2886 35.0624 33.3566 35.0624H19.5352C18.6032 35.0624 17.8477 34.3069 17.8477 33.3749V23.8392ZM19.5352 23.2767C19.2245 23.2767 18.9727 23.5286 18.9727 23.8392V33.3749C18.9727 33.6856 19.2245 33.9374 19.5352 33.9374H33.3566C33.6672 33.9374 33.9191 33.6856 33.9191 33.3749V23.8392C33.9191 23.5286 33.6672 23.2767 33.3566 23.2767H19.5352Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0605 26.4644C21.0605 26.1537 21.3124 25.9019 21.623 25.9019H31.2659C31.5766 25.9019 31.8284 26.1537 31.8284 26.4644C31.8284 26.775 31.5766 27.0269 31.2659 27.0269H21.623C21.3124 27.0269 21.0605 26.775 21.0605 26.4644Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0605 30.75C21.0605 30.4393 21.3124 30.1875 21.623 30.1875H31.2659C31.5766 30.1875 31.8284 30.4393 31.8284 30.75C31.8284 31.0607 31.5766 31.3125 31.2659 31.3125H21.623C21.3124 31.3125 21.0605 31.0607 21.0605 30.75Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.074 18.9375C24.371 18.9374 23.7467 19.3871 23.5241 20.054L25.074 18.9375ZM24.5912 20.4103C24.5912 20.4103 24.5912 20.4102 24.5912 20.4103L24.0108 22.1518H28.8787L28.2983 20.4103C28.229 20.2025 28.0345 20.0625 27.8155 20.0625H25.074C24.8551 20.0625 24.6606 20.2026 24.5912 20.4103ZM25.074 18.9375H27.8154C27.8154 18.9375 27.8154 18.9375 27.8154 18.9375C28.5185 18.9374 29.1428 19.3871 29.3655 20.054L30.1927 22.5365C30.2499 22.708 30.2211 22.8965 30.1154 23.0432C30.0096 23.1899 29.8398 23.2768 29.659 23.2768H23.2305C23.0497 23.2768 22.8799 23.1899 22.7742 23.0432C22.6684 22.8965 22.6397 22.708 22.6968 22.5365L23.5241 20.054" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5527 21.0803C20.8634 21.0803 21.1152 21.3322 21.1152 21.6428V22.7143C21.1152 23.0249 20.8634 23.2768 20.5527 23.2768C20.2421 23.2768 19.9902 23.0249 19.9902 22.7143V21.6428C19.9902 21.3322 20.2421 21.0803 20.5527 21.0803Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.3379 21.0803C32.6486 21.0803 32.9004 21.3322 32.9004 21.6428V22.7143C32.9004 23.0249 32.6486 23.2768 32.3379 23.2768C32.0272 23.2768 31.7754 23.0249 31.7754 22.7143V21.6428C31.7754 21.3322 32.0272 21.0803 32.3379 21.0803Z" fill="white"/>
    </g>
    ${circleSmallIcons({ vehicleCount })}
  </svg>
`;
export const oneDigitHoverBusiness = (color, vehicleCount) => `
  <svg viewBox="0 0 72 74" width="72px" height="74px" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
    <defs>
      <filter id="filter0_d_8000_25677" x="4" y="0" width="64" height="73.4546" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8000_25677"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8000_25677" result="shape"/>
      </filter>
      <clipPath id="clip0_8000_25677">
        <rect width="32" height="32" fill="white" transform="translate(20 15)"/>
      </clipPath>

    </defs>
    <path opacity="0.2" d="M35.9999 68C41.6401 68 46.2125 66.4759 46.2125 64.5958C46.2125 62.7158 41.6401 61.1917 35.9999 61.1917C30.3597 61.1917 25.7874 62.7158 25.7874 64.5958C25.7874 66.4759 30.3597 68 35.9999 68Z" fill="#333333" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <g filter="url(#filter0_d_8000_25677)" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)">
      <path d="M10 30.5612C10 15.902 21.63 4 36 4C50.37 4 62 15.902 62 30.5612C62 43.7852 52.4953 54.892 39.9009 56.8254V61.5784C39.9009 63.7191 38.1544 65.4546 36 65.4546C33.8456 65.4546 32.0991 63.7191 32.0991 61.5784V56.8254C19.5047 54.892 10 43.7852 10 30.5612Z" fill="#037B00"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.7905 55.0143L39.5807 54.7395C51.1188 52.9682 59.8896 42.7704 59.8896 30.5612C59.8896 17.0237 49.1611 6.11039 36 6.11039C22.8389 6.11039 12.1104 17.0237 12.1104 30.5612C12.1104 42.7704 20.8813 52.9682 32.4193 54.7395L34.2095 55.0143V61.5784C34.2095 62.541 34.9985 63.3442 36 63.3442C37.0015 63.3442 37.7905 62.541 37.7905 61.5784V55.0143ZM39.9009 56.8254C52.4953 54.892 62 43.7852 62 30.5612C62 15.902 50.37 4 36 4C21.63 4 10 15.902 10 30.5612C10 43.7852 19.5047 54.892 32.0991 56.8254V61.5784C32.0991 63.7191 33.8456 65.4546 36 65.4546C38.1544 65.4546 39.9009 63.7191 39.9009 61.5784V56.8254Z" fill="white" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)"/>
    <g clip-path="url(#clip0_8000_25677)" transform="matrix(1, 0, 0, 1, 8.881784197001252e-16, 8.881784197001252e-16)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5293 26.7856C24.5293 25.543 25.5367 24.5356 26.7793 24.5356H45.2079C46.4505 24.5356 47.4579 25.543 47.4579 26.7856V39.4999C47.4579 40.7426 46.4505 41.7499 45.2079 41.7499H26.7793C25.5367 41.7499 24.5293 40.7426 24.5293 39.4999V26.7856ZM26.7793 26.0356C26.3651 26.0356 26.0293 26.3714 26.0293 26.7856V39.4999C26.0293 39.9141 26.3651 40.2499 26.7793 40.2499H45.2079C45.6221 40.2499 45.9579 39.9141 45.9579 39.4999V26.7856C45.9579 26.3714 45.6221 26.0356 45.2079 26.0356H26.7793Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M28.8164 30.2856C28.8164 29.8714 29.1522 29.5356 29.5664 29.5356H42.4235C42.8378 29.5356 43.1735 29.8714 43.1735 30.2856C43.1735 30.6999 42.8378 31.0356 42.4235 31.0356H29.5664C29.1522 31.0356 28.8164 30.6999 28.8164 30.2856Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M28.8164 36C28.8164 35.5858 29.1522 35.25 29.5664 35.25H42.4235C42.8378 35.25 43.1735 35.5858 43.1735 36C43.1735 36.4142 42.8378 36.75 42.4235 36.75H29.5664C29.1522 36.75 28.8164 36.4142 28.8164 36Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M34.1671 20.25C33.2297 20.2499 32.3973 20.8495 32.1004 21.7387L34.1671 20.25ZM33.5232 22.2137C33.5232 22.2137 33.5233 22.2136 33.5232 22.2137L32.7495 24.5357H39.2399L38.4662 22.2137C38.3737 21.9367 38.1144 21.75 37.8224 21.75H34.1671C33.8751 21.75 33.6158 21.9368 33.5232 22.2137ZM34.1671 20.25H37.8222C37.8222 20.25 37.8223 20.25 37.8222 20.25C38.7596 20.2499 39.5921 20.8495 39.889 21.7387L40.992 25.0486C41.0682 25.2773 41.0298 25.5287 40.8888 25.7243C40.7479 25.9198 40.5215 26.0357 40.2804 26.0357H31.709C31.4679 26.0357 31.2415 25.9198 31.1006 25.7243C30.9596 25.5287 30.9212 25.2773 30.9975 25.0486L32.1004 21.7387" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1367 23.1072C28.5509 23.1072 28.8867 23.443 28.8867 23.8572V25.2857C28.8867 25.7 28.5509 26.0357 28.1367 26.0357C27.7225 26.0357 27.3867 25.7 27.3867 25.2857V23.8572C27.3867 23.443 27.7225 23.1072 28.1367 23.1072Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M43.8516 23.1072C44.2658 23.1072 44.6016 23.443 44.6016 23.8572V25.2857C44.6016 25.7 44.2658 26.0357 43.8516 26.0357C43.4373 26.0357 43.1016 25.7 43.1016 25.2857V23.8572C43.1016 23.443 43.4373 23.1072 43.8516 23.1072Z" fill="white"/>
    </g>
    ${circleBigIcons({ vehicleCount, color: '#009900', opacity: 1 })}
  </svg>
`;

export const twoDigitsBusiness = (color, vehicleCount) => `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43" height="50" viewBox="0 0 43 50" xmlns:bx="https://boxy-svg.com">
    <defs>
        <path id="b" d="M10.87 31.16C4.55 29.022 0 23.042 0 16 0 7.163 7.163 0 16 0s16 7.163 16 16c0 7.042-4.55 13.022-10.87 15.16L16 38l-5.13-6.84z"/>
        <filter id="a" width="146.9%" height="141.2%" x="-23.4%" y="-17.1%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius="1" result="shadowSpreadOuter1"/>
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        </filter>
        ${importFont()}
    </defs>
    <g fill="none" fill-rule="evenodd">
        <g transform="translate(5 6)">
            <use fill="#000" filter="url(#a)" xlink:href="#b"/>
            <path fill="#009900" stroke="#FFF" d="M10.562 31.583A16.506 16.506 0 0 1-.5 16C-.5 6.887 6.887-.5 16-.5S32.5 6.887 32.5 16a16.506 16.506 0 0 1-11.062 15.583L16 38.833l-5.438-7.25z"/>
        </g>
        <g transform="translate(5, 6)">
            <path d="M13.7142857,11.8461538 L13.7142857,10 L19.2857143,10 L19.2857143,11.8461538 L21.137417,11.8461538 C22.1660932,11.8461538 23,12.6770857 23,13.6849388 L23,20.1612151 C23,21.1767479 22.1670156,22 21.137417,22 L11.862583,22 C10.8339068,22 10,21.1690682 10,20.1612151 L10,13.6849388 C10,12.6694059 10.8329844,11.8461538 11.862583,11.8461538 L13.7142857,11.8461538 Z M14.6428571,10.9230769 L14.6428571,11.8461538 L18.3571429,11.8461538 L18.3571429,10.9230769 L14.6428571,10.9230769 Z" id="Combined-Shape" fill="#FFFFFF"></path>
        </g>
        <g transform="translate(27)">
            <circle cx="8" cy="8" r="7.5" fill="#FFF" stroke="#009900"/>
            <text fill="#333" style="font-family: 'NEXT Book';" font-size="12" font-weight="300">
                <tspan x="3" y="11">${vehicleCount}</tspan>
            </text>
        </g>
    </g>
</svg>
`;

export const twoDigitsHoverBusiness = (color, vehicleCount) => `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="58" height="66" viewBox="0 0 58 66" xmlns:bx="https://boxy-svg.com">
    <defs>
        <path id="b" d="M17.087 43.45C7.302 41.216 0 32.46 0 22 0 9.85 9.85 0 22 0s22 9.85 22 22c0 10.461-7.302 19.217-17.087 21.45L22 50l-4.913-6.55z"/>
        <filter id="a" width="143.2%" height="142%" x="-21.6%" y="-17%" filterUnits="objectBoundingBox">
            <feMorphology in="SourceAlpha" operator="dilate" radius="3" result="shadowSpreadOuter1"/>
            <feOffset dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2"/>
            <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
            <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
        </filter>
        ${importFont()}
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(7 3)">
        <g transform="translate(0 3)">
            <ellipse cx="22" cy="52" fill="#333" fill-opacity=".2" fill-rule="nonzero" rx="10" ry="5"/>
            <use fill="#000" filter="url(#a)" xlink:href="#b"/>
            <path fill="#009900" stroke="#FFF" stroke-width="3" d="M16.211 44.781C5.888 42.168-1.5 32.833-1.5 22-1.5 9.021 9.021-1.5 22-1.5S45.5 9.021 45.5 22c0 10.833-7.388 20.168-17.711 22.781L22 52.5l-5.789-7.719z"/>
        </g>
        <g transform="translate(-2, 1) scale(1.50)">
            <path d="M13.7142857,11.8461538 L13.7142857,10 L19.2857143,10 L19.2857143,11.8461538 L21.137417,11.8461538 C22.1660932,11.8461538 23,12.6770857 23,13.6849388 L23,20.1612151 C23,21.1767479 22.1670156,22 21.137417,22 L11.862583,22 C10.8339068,22 10,21.1690682 10,20.1612151 L10,13.6849388 C10,12.6694059 10.8329844,11.8461538 11.862583,11.8461538 L13.7142857,11.8461538 Z M14.6428571,10.9230769 L14.6428571,11.8461538 L18.3571429,11.8461538 L18.3571429,10.9230769 L14.6428571,10.9230769 Z" id="Combined-Shape" fill="#FFFFFF"></path>
        </g>
        <circle cx="39" cy="8" r="7.5" fill="#FFF" stroke="#009900"/>
        <text fill="#333" style="font-family: 'NEXT Book';" font-size="12" font-weight="300">
            <tspan x="34.5" y="10.5">${vehicleCount}</tspan>
        </text>
    </g>
</svg>
`;

export const iconBooking = () => `
<svg width="33px" height="40px" viewBox="0 0 33 40" xmlns="http://www.w3.org/2000/svg">
    <g id="Outlines" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icons" transform="translate(0.000000, 0.000000)">
            <g id="Bluemove_booking_default" transform="translate(0.000000, 0.000000)">
                <path d="M16.5,38.6403242 L20.6077126,32.4288296 L20.8699716,32.3513562 C27.5566192,30.3760665 32.25,24.0995842 32.25,16.8719169 C32.25,7.96434539 25.1946844,0.75 16.5,0.75 C7.80531563,0.75 0.75,7.96434539 0.75,16.8719169 C0.75,24.100122 5.44277418,30.3762132 12.1299962,32.3513466 L12.3922769,32.4288137 L16.5,38.6403242 Z" id="Path" stroke="#FFFFFF" stroke-width="1.5"
                      fill="#009900"></path>
                <path d="M16.5,8 C11.8063,8 8,11.8063 8,16.5 C8,21.1937 11.8063,25 16.5,25 C21.1937,25 25,21.1937 25,16.5 C25,11.8063 21.1937,8 16.5,8 M16.5,9.275 C20.48395,9.275 23.725,12.51605 23.725,16.5 C23.725,20.48395 20.48395,23.725 16.5,23.725 C12.51605,23.725 9.275,20.48395 9.275,16.5 C9.275,12.51605 12.51605,9.275 16.5,9.275 M19.0602,19.80865 L16.0852,17.25865 C15.9441,17.13795 15.8625,16.96115 15.8625,16.775 L15.8625,11.825 C15.8625,11.4731 16.1481,11.1875 16.5,11.1875 C16.8519,11.1875 17.1375,11.4731 17.1375,11.825 L17.1375,16.48175 L19.8898,18.84135 C20.15755,19.07 20.18815,19.4729 19.95865,19.7398 C19.83285,19.88685 19.65435,19.9625 19.475,19.9625 C19.32795,19.9625 19.18005,19.9115 19.0602,19.80865 Z" id="Combined-Shape" fill="#FFFFFF"></path>
            </g>
        </g>
    </g>
</svg>
`;

export const iconHoverBooking = () => `
<svg width="46px" height="56px" viewBox="0 0 46 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Outlines" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icons" transform="translate(0.000000, 0.000000)">
            <g id="Bluemove_booking_active" transform="translate(0.000000, 0.000000)">
                <g id="Group" stroke-width="1" fill="#01757d" stroke="#FFFFFF">
                    <path d="M23,54.1816114 L28.7532225,45.4440272 L29.1032208,45.3401853 C38.4433018,42.5690538 45,33.7626245 45,23.6206837 C45,11.1217714 35.1441997,1 23,1 C10.8558003,1 1,11.1217714 1,23.6206837 C1,33.7633769 7.55585267,42.5692601 16.8967361,45.3401725 L17.2467635,45.4440059 L23,54.1816114 Z" id="Path" stroke-width="2"></path>
                </g>
                <path d="M23,11 C16.3736,11 11,16.3736 11,23 C11,29.6264 16.3736,35 23,35 C29.6264,35 35,29.6264 35,23 C35,16.3736 29.6264,11 23,11 M23,12.8 C28.6244,12.8 33.2,17.3756 33.2,23 C33.2,28.6244 28.6244,33.2 23,33.2 C17.3756,33.2 12.8,28.6244 12.8,23 C12.8,17.3756 17.3756,12.8 23,12.8 M26.6144,27.6710353 L22.4144,24.0710353 C22.2152,23.9006353 22.1,23.6510353 22.1,23.3882353 L22.1,15.9882353 C22.1,15.4914353 22.5032,15.0882353 23,15.0882353 C23.4968,15.0882353 23.9,15.4914353 23.9,15.9882353 L23.9,22.9742353 L27.7856,26.3054353 C28.1636,26.6282353 28.2068,27.1970353 27.8828,27.5738353 C27.7052,27.7814353 27.4532,27.8882353 27.2,27.8882353 C26.9924,27.8882353 26.7836,27.8162353 26.6144,27.6710353 Z" id="Combined-Shape" fill="#FFFFFF"></path>
            </g>
        </g>
    </g>
</svg>
`;

export const iconTripStart = () => `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="nonzero">
        <path fill="#FFF" d="M1 12L11.995 1 23 12 11.995 23 1 12z"/>
        <path fill="#888888" d="M12 2.828L2.828 12 12 21.172 21.172 12 12 2.828zM12 0l12 12-12 12L0 12 12 0z"/>
    </g>
</svg>
`;

export const iconTripEnd = () => `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fill-rule="nonzero">
        <path fill="#FFF" d="M1 12L11.995 1 23 12 11.995 23 1 12z"/>
        <path fill="#888888" d="M2.828 11.948l9.12 9.12 9.12-9.12-9.12-9.12-9.12 9.12zM11.948 0l11.948 11.948-11.948 11.948L0 11.948 11.948 0zM12 7l-5 5 5 5 5-5-5-5z"/>
    </g>
</svg>
`;

export const cluster = () => Cluster;

export const userMarker = () => userMarkerIcon;

export const pointerMarker = () => `
  <svg width="72" height="75" viewBox="0 0 72 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.2"
          d="M35.9999 68.6331C41.6401 68.6331 46.2125 67.109 46.2125 65.2289C46.2125 63.3488 41.6401 61.8247 35.9999 61.8247C30.3597 61.8247 25.7874 63.3488 25.7874 65.2289C25.7874 67.109 30.3597 68.6331 35.9999 68.6331Z"
          fill="#333333" />
      <g filter="url(#filter0_d_8000_25945)">
          <path
              d="M10 31.1943C10 16.5351 21.63 4.63306 36 4.63306C50.37 4.63306 62 16.5351 62 31.1943C62 44.4183 52.4953 55.525 39.9009 57.4585V62.2114C39.9009 64.3522 38.1544 66.0876 36 66.0876C33.8456 66.0876 32.0991 64.3522 32.0991 62.2114V57.4585C19.5047 55.525 10 44.4183 10 31.1943Z"
              fill="#037B00" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd"
          d="M37.7905 55.6473L39.5807 55.3725C51.1188 53.6012 59.8896 43.4034 59.8896 31.1943C59.8896 17.6568 49.1611 6.74345 36 6.74345C22.8389 6.74345 12.1104 17.6568 12.1104 31.1943C12.1104 43.4034 20.8813 53.6012 32.4193 55.3725L34.2095 55.6473V62.2114C34.2095 63.174 34.9985 63.9772 36 63.9772C37.0015 63.9772 37.7905 63.174 37.7905 62.2114V55.6473ZM39.9009 57.4585C52.4953 55.525 62 44.4183 62 31.1943C62 16.5351 50.37 4.63306 36 4.63306C21.63 4.63306 10 16.5351 10 31.1943C10 44.4183 19.5047 55.525 32.0991 57.4585V62.2114C32.0991 64.3522 33.8456 66.0876 36 66.0876C38.1544 66.0876 39.9009 64.3522 39.9009 62.2114V57.4585Z"
          fill="white" />
      <circle cx="36" cy="24.6331" r="4.25" fill="white" stroke="white" stroke-width="1.5" />
      <path
          d="M35 30.6331H37V42.6331C37 43.1853 36.5523 43.6331 36 43.6331C35.4477 43.6331 35 43.1853 35 42.6331V30.6331Z"
          fill="white" />
      <circle cx="34" cy="22.6331" r="1" fill="#037B00" />
      <defs>
          <filter id="filter0_d_8000_25945" x="4" y="0.633057" width="64" height="73.4546" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="3" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8000_25945" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8000_25945" result="shape" />
          </filter>
      </defs>
  </svg>
`;
